<template>
  <div class="no_login_pay">
    <div class="goods_details">
      <div class="photo">
        <img :src="clearingGoods[0].product_icon" alt="" />
      </div>
      <div class="info">
        <div class="status">{{ $t('noLogin.qxss') }}</div>
        <div class="goods_name">{{ clearingGoods[0].product_name }}</div>
        <div class="goods_num">
          <span>{{ $t('noLogin.sl') }}：</span>
          <div class="number">
            {{ clearingGoods[0].product_num }}
          </div>
        </div>
        <div class="goods_price">
          <span>{{ $t('noLogin.yfje') }}：</span>￥{{ clearingGoods[0].subtotal }}
        </div>
      </div>
    </div>
    <div class="userInfo">
      <h2>{{ $t('noLogin.gmxx') }}</h2>
      <div class="receiving_info">
        <div class="title">{{ $t('noLogin.shxx') }}</div>
        <el-form :model="userInfo" status-icon :rules="ruless" ref="regist" label-width="100px">
          <el-form-item :label="$t('noLogin.shr')" prop="username">
            <el-input v-model="userInfo.username" :placeholder="$t('noLogin.qsrndzwmhywm')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('noLogin.shdz')" prop="address">
            <el-input v-model="userInfo.address" :placeholder="$t('noLogin.qsrndshdz')">
            </el-input>
          </el-form-item>
          <el-form-item :label="$t('noLogin.lxdh')" prop="phone">
            <el-input v-model="userInfo.phone" :placeholder="$t('noLogin.qsrndlxfs')"></el-input>
          </el-form-item>
          <el-form-item :label="$t('noLogin.yzm')" prop="messageContent">
            <div class="getCode">
              <el-input style="margin-right: 20px" v-model="userInfo.messageContent"
                :placeholder="$t('noLogin.qsryzm')"></el-input>
              <getCode @again="userPhoneAgain" @getCode="registerGetCode" :isHave="phoneIsHave"
                :disabled="userInfo.disabled" />
            </div>
          </el-form-item>
          <div style="width: 20px"></div>
          <el-button type="goon" duration="5000" @click="addPay('regist')">{{ $t('noLogin.ljzf') }} </el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import getCode from "../components/common/getCode.vue";
export default {
  name: "goodsDetail",
  components: { getCode },
  data() {
    let phone = (rule, value, callback) => {
      let myreg = /^[1][3,4,5,7,8,9][0-9]{9}$/;
      if (!myreg.test(value)) {
        callback(this.$t('noLogin.qsrzqdsjh'));
        this.userInfo.disabled = true;
      } else {
        callback();
        if (this.userInfo.phoneDisabledNum == 0) {
          this.userInfo.disabled = false;
        }
      }
    };
    return {
      isGoods: false,
      isStatus: false,
      isSub: false,
      isName: false,
      isPhone: false,
      isAddress: false,
      phoneIsHave: false,
      goods_details: {
        id: 1,
        name: "X300智能安全帽",
        describe: "智能硬件设备搭载可定制化软件系统",
        photo: [],
        num: 1,
        price: 1999,
      },
      userInfo: {
        username: "",
        phone: "",
        address: "",
        corporation: "",
        messageContent: "",
        disabled: true,
        phoneDisabledNum: 0,
      },
      ruless: {
        username: [
          {
            required: true,
            message: this.$t('noLogin.qsrndzwmhywm'),
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: this.$t('noLogin.qsrndshdz'), trigger: "blur" },
        ],
        phone: [
          { required: true, message: this.$t('noLogin.qsrndlxfs'), trigger: "blur" },
          { validator: phone, trigger: "change" },
        ],
        messageContent: [
          { required: true, message: this.$t('noLogin.qsryzm'), trigger: "blur" },
        ],
      },
      payment: [
        {
          name: this.$t('noLogin.zfb'),
          type: "ALIPAY_PC",
          img: require("../assets/pay/a9936a369e82e0c6c42112674a5220e8_fullsize.jpg"),
        },
        {
          name: this.$t('noLogin.wx'),
          type: "WXPAY_NATIVE",
          img: require("../assets/pay/u=3774939867,2826752539&fm=74&app=80&f=JPEG&size=f121,121.jpg"),
        },
      ],
      clearingGoods: [],
      ordersGoods: [],
      ss: {},
      isRegist: false,
    };
  },

  mounted() {
    // this.clearingGoods =
    //   JSON.parse(localStorage.getItem("commodityInfo")) || [];
    // console.log(this.clearingGoods[0].product_name);
    // this.clearingGoods.forEach((item) => {
    //   let data = {
    //     code: item.product_id,
    //     num: item.product_num,
    //   };
    //   this.ordersGoods.push(data);
    //   console.log(this.ordersGoods.num);
    // });
    // console.log(localStorage.getItem('params'))
    if (localStorage.getItem('params')) {
      // console.log('ok')
      this.$router.push({
        path: '/pay'
      })
    }
  },

  created() {
    this.clearingGoods =
      JSON.parse(localStorage.getItem("commodityInfo")) || [];
    console.log(this.clearingGoods)
    this.clearingGoods.forEach((item) => {
      let data = {
        code: item.product_id,
        num: item.product_num,
      };
      this.ordersGoods.push(data);
    });
  },

  methods: {
    changeNum(num) {
      if (num) {
        this.isSub = false;
        this.goods_details.num++;
      } else {
        if (this.goods_details.num == 1) {
          this.isSub = true;
          return;
        }
        this.goods_details.num--;
      }
    },

    // 支付
    async addPay(formName) {
      this.register(formName);
    },

    async registerInterface() {
      // 注册接口;
      let params = {
        language: "CN",
        username: this.userInfo.username,
        password: "baba" + this.userInfo.phone.slice(-4),
        phone: this.userInfo.phone,
        smscode: this.userInfo.messageContent,
      };
      let res = await this.api.post(this.apiPath.registered, params);
      var that = this
      if (res.code == 0) {

          that.$message({
            message: `您的初始密码为'+'baba${ this.userInfo.phone.slice(-4)}`,
            type: "success",
   
        })
        this.login();
      } else {
        if (res.msg == this.$t('noLogin.gsjhybzy')) {
          this.isRegist = true;
          this.$router.push({
            name: "login",
            params: { reg: true },
          });
        }
        this.$message.error(this.$t('noLogin.gsjhmyzcqdl'));
      }
    },
    // 注册
    register(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.registerInterface();
        } else {
          this.isRegist = true;
          return false;
        }
      });
    },
    // 登录
    async login() {
      let params = {
        id: "1",
        // username: "18193458090",
        // password: "wl18193458090",
        username: this.userInfo.phone,
        password: "baba" + this.userInfo.phone.slice(-4),
      };
      let res = await this.api.post(this.apiPath.login, params);
      if (res.token) {
        localStorage.setItem("params", JSON.stringify(params));
        localStorage.setItem("userMap", JSON.stringify(res.userMap));
        localStorage.setItem("token", res.token);
        // this.$router.go(-1);
        // this.loading = false;
        this.pay();
      } else {
        this.$message.error(res.msg);
        this.loading = false;
      }
    },
    userPhoneAgain() {
      this.userInfo.disabled = false;
      this.userInfo.phoneDisabledNum = 0;
    },
    async registerGetCode() {
      //获取手机验证码
      let params = {
        phone: this.userInfo.phone,
      };
      let res = await this.api.post(this.apiPath.verificationCode, params);
      this.userInfo.disabled = true;
      if (res.code == 0) {
        this.phoneIsHave = true;
        // this.$message(res.msg);
        this.$message({
          message: res.msg,
          type: "success",
        });
      } else {
        this.$message.error(res.msg);
      }
    },
    // 提交订单
    async pay() {
      this.clearingGoods.forEach((i) => {
        this.ordersGoods.forEach((j) => {
          if (j.product_id == i.code) {
            if (i.remark) {
              j.remark = i.remark;
            }
          }
        });
      });
      const buyType = localStorage.getItem("buyType");
      let data = {
        total_price: this.clearingGoods[0].subtotal,
        // address_id: this.selectedAddress,
        carts: this.ordersGoods,
        buy_type: buyType * 1,
        address_id: 1,
        addresss: this.userInfo.address,
        lxdh: this.userInfo.phone,
        regname: this.userInfo.name,
      };

      let res = await this.api.post(this.apiPath.createOrder, data);
      if (res.code == 200) {
        localStorage.setItem("order_no", JSON.stringify(res.data.order_no));
        this.$router.push({
          name: "payment",
        });
        let num = Math.round(Math.random() * 1100);
        localStorage.setItem("shoppingNum", num);
      } else {
        this.$message({
          message: res.message,
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.no_login_pay {
  width: 1210px;
  margin: auto;

  .goods_details {
    display: flex;
    align-items: center;

    .photo {
      width: 400px;
      height: 400px;
      border: 1px solid #000;
      margin-right: 50px;
      margin-bottom: 0;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      .status {
        width: 80px;
        height: 30px;
        line-height: 30px;
        background: linear-gradient(to bottom, red, #000);
        color: #fff;
        text-align: center;
      }

      .goods_name {
        margin: 20px 0;
        font-size: 30px;
        font-weight: 700;
        color: #000;
      }

      .goods_color {
        display: flex;
        align-items: center;
        margin: 30px 0 20px;

        .color_list {
          display: flex;
          margin-left: 20px;

          .color_photo {
            position: relative;
            width: 70px;
            height: 70px;
            margin-right: 10px;
            border: 1px solid #d7d4d4;

            .no_goods {
              position: absolute;
              top: 0;
              right: 0;
              width: 35px;
              background: #dadada;
              // transform: scale(0.7);
              font-size: 12px;
              text-align: center;
              line-height: 14px;
              color: #fff;
            }

            img {
              width: 100%;
              height: 100%;
            }
          }

          .color_photo:last-child {
            margin-right: 0;
          }

          .active {
            border: 1px solid #000;
          }
        }
      }

      .goods_num {
        display: flex;
        align-items: center;
        margin: 30px 0 0;

        .number_input {
          margin-left: 20px;

          button {
            width: 30px;
            height: 30px;
            vertical-align: middle;
            border: 1px solid #dadada;
          }

          button[disabled] {
            background: #f0f0f0;
            color: #000;
          }

          input {
            width: 80px;
            height: 30px;
            vertical-align: middle;
            border-radius: 0;
            // border: 1px solid #dadada;
            text-align: center;
          }
        }
      }

      .goods_price {
        margin: 20px 0;
        font-size: 20px;
        font-weight: bold;
        color: #f00;

        span {
          font-weight: normal;
          font-size: 14px;
          color: #666;
        }
      }
    }
  }

  .userInfo {
    margin: 20px 0;

    h2 {
      font-size: 20px;
      font-weight: bold;
    }

    .receiving_info {
      .title {
        margin: 10px 0;
        font-size: 16px;
      }

      .el-form {
        display: flex;
        flex-flow: wrap;
        justify-content: space-between;

        .el-form-item {
          width: 50%;
          margin-bottom: 25px;

          .getCode {
            display: flex;
          }
        }
      }
    }

    .pay_info {
      .pay_method {
        .title {
          margin-bottom: 10px;
          font-size: 16px;
        }

        .methods {
          display: flex;

          .pay {
            flex: 1;
            margin-bottom: 10px;

            img {
              width: 60px;
              vertical-align: middle;
              border-radius: 10px;
            }
          }
        }
      }

      .sum {
        flex: 1;

        .title {
          margin-bottom: 10px;
          font-size: 16px;
        }

        .total {
          margin-right: 5px;
          font-size: 25px;
          font-weight: bold;
        }

        span {
          font-size: 12px;
        }
      }
    }
  }

  .el-button--goon {
    color: #fff;
    background-color: #7f0114;
    border-color: #7f0114;
    height: 44px;
    line-height: 34px;
    padding: 0 20px;
  }

  .el-button--goon:focus,
  .el-button--goon:hover {
    color: #fff;
    background-color: #813f49;
    border-color: #813f49;
    height: 44px;
    line-height: 34px;
    padding: 0 20px;
  }

  /deep/ .el-input-number:hover {
    border-color: #813f49 !important;
  }
}

@media screen and (max-width: 750px) {
  .no_login_pay {
    width: 100%;
    padding: 0 20px;

    .goods_details {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .photo {
        width: 100%;
        height: auto;
        border: 1px solid #000;
        margin-right: 0;
        margin-bottom: 20px;

        img {
          width: 100%;
          height: 100%;
          vertical-align: middle;
        }
      }

      .info {
        .status {
          width: 80px;
          height: 30px;
          line-height: 30px;
          background: linear-gradient(to bottom, red, #000);
          color: #fff;
          text-align: center;
        }

        .goods_name {
          margin: 20px 0;
          font-size: 30px;
          font-weight: 700;
          color: #000;
        }

        .goods_color {
          display: flex;
          align-items: center;
          margin: 30px 0 20px;

          .color_list {
            display: flex;
            margin-left: 20px;

            .color_photo {
              position: relative;
              width: 70px;
              height: 70px;
              margin-right: 10px;
              border: 1px solid #d7d4d4;

              .no_goods {
                position: absolute;
                top: 0;
                right: 0;
                width: 35px;
                background: #dadada;
                // transform: scale(0.7);
                font-size: 12px;
                text-align: center;
                line-height: 14px;
                color: #fff;
              }

              img {
                width: 100%;
                height: 100%;
              }
            }

            .color_photo:last-child {
              margin-right: 0;
            }

            .active {
              border: 1px solid #000;
            }
          }
        }

        .goods_num {
          display: flex;
          align-items: center;
          margin: 30px 0 0;

          .number_input {
            margin-left: 20px;

            button {
              width: 30px;
              height: 30px;
              vertical-align: middle;
              border: 1px solid #dadada;
            }

            button[disabled] {
              background: #f0f0f0;
              color: #000;
            }

            input {
              width: 80px;
              height: 30px;
              vertical-align: middle;
              border-radius: 0;
              // border: 1px solid #dadada;
              text-align: center;
            }
          }
        }

        .goods_price {
          margin: 20px 0;
          font-size: 20px;
          font-weight: bold;
          color: #f00;

          span {
            font-weight: normal;
            font-size: 14px;
            color: #666;
          }
        }
      }
    }

    .userInfo {
      margin: 20px 0;

      h2 {
        font-size: 20px;
        font-weight: bold;
      }

      .receiving_info {
        .title {
          margin: 10px 0;
          font-size: 16px;
        }

        .el-form {
          display: flex;
          flex-flow: wrap;
          width: 100%;
          justify-content: space-between;

          .el-form-item {
            width: 100%;
            margin-bottom: 25px;

            .getCode {
              display: flex;
            }
          }
        }
      }
    }

    .el-button--goon {
      color: #fff;
      background-color: #7f0114;
      border-color: #7f0114;
      height: 44px;
      line-height: 34px;
      padding: 0 20px;
    }

    #video {
      width: 200px;
    }

    .el-button--goon:focus,
    .el-button--goon:hover {
      color: #fff;
      background-color: #813f49;
      border-color: #813f49;
      height: 44px;
      line-height: 34px;
      padding: 0 20px;
    }
  }
}

@media screen and (max-width: 750px) {
  #video {
    width: 100% !important;
    height: 250px !important;
  }
}
.el-message--success{
  width: 260px;
  height: 80px;
}
</style>
